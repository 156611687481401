<template>
  <div class="layer-container">
    <div>
      <multiselect :multiple="false" :id="'language_select' + Math.random()"
        :options="blocker_options"
        v-model="selectedBlocker"
        placeholder="Select blocker"
        class="mb-1"
      >
        <template slot="singleLabel" slot-scope="props">
          <div class="d-flex align-items-center">
            <div class="mr-1" style="padding: 8px" :style="{backgroundColor: props.option.color}"></div>
            <span>{{ props.option.text }}</span>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="d-flex align-items-center">
            <div class="mr-1" style="padding: 8px" :style="{backgroundColor: props.option.color}"></div>
            <span>{{ props.option.text }}</span>
          </div>
        </template>
      </multiselect>
    </div>
    <div>
      <div class="d-flex align-items-center">
        <h5 class="mr-1">Layer {{ layer_index + 1 }}</h5>
        <span class="d-block" v-html="cardCount" style="margin-bottom: 0.5rem;"></span>
      </div>
      <div class="text-right">
        <b-button
          variant="danger"
          size="sm"
          @click="confirmDelete"
        >
            <slot>
              <feather-icon icon="Trash2Icon" />
            </slot>
        </b-button>
        <div class="grid">
          <div v-for="(row, rowIndex) in grid" :key="rowIndex">
            <div v-for="(col, colIndex) in row" :key="colIndex" 
              @mousedown.exact="startSelection(rowIndex, colIndex)"
              @mousedown.ctrl.exact="startSelection(rowIndex, colIndex, true)" 
              @mousemove="handleDrag(rowIndex, colIndex)"
              @mouseup.exact="endSelection(rowIndex, colIndex)" 
              @mouseup.ctrl.exact="endSelection(rowIndex, colIndex, true)"
              class="grid-item"
              :style="{backgroundColor: isSelected(rowIndex, colIndex) ? (selectedBlocker ? selectedBlocker.color : defaultSelectedColor) : grid[rowIndex][colIndex].color}"
              :class="{'horizontal-border': (rowIndex === 6), 'vertical-border': (colIndex === 4)}"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
const ROW_LENGTH = 10;
const COL_LENGTH = 14;
export default {
  components: {
    Multiselect
  },
  props: {
    layer_map: { type: Array, required: true },
    layer_index: { type: Number, required: true },
    blocker_options: {type: Array, default: ()=>{return []}},
  },
  data() {
    return {
      grid: Array.from({ length: COL_LENGTH }, () => Array(ROW_LENGTH).fill({is_active: false, color: null})),
      selecting: false,
      startCell: { row: null, col: null },
      endCell: { row: null, col: null },
      isDeleting: false,
      selectedBlocker: null,
      defaultSelectedColor: '#a3d3f1',
      localLayerMap: []
    };
  },
  created() {
    this.convertLayerMapToGrid();
    this.localLayerMap = [...this.layer_map];
  },
  computed: {
    cardCount(){
      return `( Total cards: <span style='color: ${this.localLayerMap.length % 3 !== 0 ? 'red' : '#55DD92'}'>${this.localLayerMap.length}</span> )`
    }
  },
  methods: {
    startSelection(rowIndex, colIndex, isDeleting = false) {
      this.startCell.row = rowIndex;
      this.startCell.col = colIndex;
      if (!isDeleting) {
        this.selecting = true;
      } else {
        this.isDeleting = true
      }
    },
    handleDrag(rowIndex, colIndex) {
      if (this.selecting || this.isDeleting) {
        this.endCell.row = rowIndex;
        this.endCell.col = colIndex;
      }
    },
    endSelection(rowIndex, colIndex, isDeleting = false) {
      this.endCell.row = rowIndex;
      this.endCell.col = colIndex;
      this.selecting = false;
      this.isDeleting = false;
      this.updateGrid(isDeleting);
      this.startCell = { row: null, col: null };
      this.endCell = { row: null, col: null };

    },
    selectCell(row, col) {
      if (!this.selecting) {
        this.startCell = { row, col };
        this.endCell = { row, col };
      }
    },
    isSelected(row, col) {
      if ((this.selecting || this.isDeleting)
        && typeof this.startCell.row === 'number'
        && typeof this.startCell.col === 'number'
        && typeof this.endCell.row === 'number'
        && typeof this.endCell.col === 'number') {
        const minRow = Math.min(this.startCell.row, this.endCell.row);
        const maxRow = Math.max(this.startCell.row, this.endCell.row);
        const minCol = Math.min(this.startCell.col, this.endCell.col);
        const maxCol = Math.max(this.startCell.col, this.endCell.col);
        if (this.selecting) {
          return row >= minRow && row <= maxRow && col >= minCol && col <= maxCol;
        }
        if (this.isDeleting) {
          if (row >= minRow && row <= maxRow && col >= minCol && col <= maxCol) {
            if (this.grid[row][col]) {
              this.grid[row][col] = false
              return false
            }
          }
        }
        return false
      }
    },
    updateGrid(isDeleting = false) {
      let minRow = Math.min(this.startCell.row, this.endCell.row);
      let maxRow = Math.max(this.startCell.row, this.endCell.row);
      let minCol = Math.min(this.startCell.col, this.endCell.col);
      let maxCol = Math.max(this.startCell.col, this.endCell.col);
      if (this.startCell.row === this.endCell.row && this.startCell.col === this.endCell.col){
        if (
          this.startCell.col == ROW_LENGTH - 1 || 
          this.startCell.row == COL_LENGTH - 1 
          ){
          return
        }
        if (isDeleting && (!this.grid[this.startCell.row][this.startCell.col].is_active ||
          !this.grid[this.startCell.row + 1][this.startCell.col + 1].is_active ||
          !this.grid[this.startCell.row + 0][this.startCell.col + 1].is_active ||
          !this.grid[this.startCell.row + 1][this.startCell.col + 0].is_active )){
            return
        }
        maxRow += 1;
        maxCol += 1;
      } else {
        if ((Math.abs(this.startCell.row - this.endCell.row) + 1) % 2 !== 0){
          maxRow -= 1;
        }
        if ((Math.abs(this.startCell.col - this.endCell.col) + 1) % 2 !== 0){
          maxCol -= 1;
        }
      }
      if (!isDeleting && (this.grid[this.startCell.row][this.startCell.col].is_active ||
        this.grid[this.startCell.row + 1][this.startCell.col + 1].is_active ||
        this.grid[this.startCell.row + 0][this.startCell.col + 1].is_active ||
        this.grid[this.startCell.row + 1][this.startCell.col + 0].is_active )){
          return
      }
      let currentNode = {
        row_num: minRow,
        col_num: minCol,
        layer_num: this.layer_index + 1,
        block_type: this.selectedBlocker?.type
      }
      let newNode = isDeleting ? false : true;
      for (let row = minRow; row <= maxRow; row++) {
        
        for (let col = minCol; col <= maxCol; col++) {
          if (
            !isDeleting
          ){
            if (currentNode.row_num + 2 === row){
              currentNode.row_num = row;
              currentNode.col_num = col;
              newNode = true;
            }
            if (currentNode.col_num + 2 === col){
              currentNode.row_num = row;
              currentNode.col_num = col;
              newNode = true;
            }
          }
          
          this.grid[row][col] = !isDeleting ? {is_active: true, color: this.selectedBlocker?.color || '#a3d3f1'} : {is_active: false, color: null};
          if (newNode && currentNode.row_num + 1 <= maxRow && currentNode.col_num + 1 <= maxCol) {
            this.localLayerMap.push({ row_num: row, col_num: col, block_type: this.selectedBlocker?.type || null, color: this.selectedBlocker?.color || null });
            newNode = false;
          }
          if (!this.grid[row][col].is_active) {
            this.localLayerMap = this.localLayerMap.filter((item) => !(item.row_num === row && item.col_num === col));
          }
        }
      }
      this.$emit('update_layer', this.localLayerMap)
      this.$forceUpdate()
    },
    convertLayerMapToGrid() {
      let nodeColor = null;
      for (let node of this.layer_map) {
        nodeColor = this.blocker_options.find((item) => {return item.type === node.block_type})?.color
        let nodeObj = {is_active: true, color: nodeColor || this.defaultSelectedColor}
        this.grid[node.row_num][node.col_num] = nodeObj;
        this.grid[node.row_num + 1][node.col_num] = nodeObj;
        this.grid[node.row_num][node.col_num + 1] = nodeObj;
        this.grid[node.row_num + 1][node.col_num + 1] = nodeObj;
      }
    },
    confirmDelete(){
      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: `Please confirm to delete Layer ${this.layer_index + 1}`,
          size: 'md',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$emit('delete_layer', this.layer_index);
          }
        })
    }
  }
};
</script>
  
<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(14, 30px);
  grid-template-rows: repeat(10, 30px);
  gap: 1px;
  border: 1px solid #ccc;
}

.grid-item {
  width: 30px;
  height: 30px;
  background-color: #eee;
  border: 1px solid #fff;
  user-select: none;
}

.horizontal-border {
  border-right: 2px dashed red; /* Vertical border */
}

.vertical-border {
  border-bottom: 2px dashed red;
}
.selected {
  background-color: #a3d3f1;
}

.layer-container {
  display: table;

}
</style>
  