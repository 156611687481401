import api from "@/libs/axios";

export default {
  async create(payload) {
    const response = await api.post("/admin/tile-rank/map-pool", payload);
    return response.data.data.new_document || null
  },

  async update(payload) {
    let response = await api.put("/admin/tile-rank/map-pool", payload);
    return response;
  },

  async delete(payload) {
    const response = await api.delete("/admin/tile-rank/map-pool", {
      data: payload
    });
    return response;
  },

  async getList(params) {
    let response = await api.get("/admin/tile-rank/map-pool", {
      params
    });
    return response.data.data;
  },

  async get(params) {
    let response = await api.get("/admin/tile-rank/map-pool/detail", {
      params
    });
    return response.data.data.document || null;
  }
};
