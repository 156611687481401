<template>
	<div v-if="level && isReady" class="page-content">
		<page-breadcrumb title="Level Detail" class="mb-2" :items="breadcrumbItems" />
		<section class="mb-2">
			<h4>Overview</h4>
			<b-card class="mt-1">
				<form-generator :model="level" :schema="overview_form_schema" />
			</b-card>
			<h4>Details</h4>
			<b-card class="mt-1">
				<form-generator :model="level" :schema="detail_form_schema" >
					<template #slot_random_item>
						<b-row>
							<b-col cols="3">
								<b-input v-model="random_item_numb"></b-input>
							</b-col>
							<b-col cols="2">
								<b-button variant="success" @click="randomItem" :disabled="!random_item_numb">Random</b-button>	
							</b-col>
						</b-row>
					</template>
				</form-generator>

				<!--Rewards-->
				<span>Rewards</span>
				<b-row v-for="(reward, index) in level.rewards" :key="reward.reward_id + index" class="justify-content-center mb-1">
					<b-col cols="5">
						<span>Reward</span>
						<multi-select
							v-if="reward_options"
							:id="`reward-select-input`"
							:options="reward_options"
							:multiple="false"
							v-model="reward.reward_id"
						/>
					</b-col>
					<b-col cols="5">
						<span>Quantity</span>
						<b-input v-model="reward.quantity" type="number" />
					</b-col>
				</b-row>
				<div class="text-center mb-2">
					<b-button variant="success" @click="addReward">Add +</b-button>
				</div>

				<!--Goals-->
				<!-- <span>Goals</span>
				<b-row v-for="(goal, index) in level.goals" :key="goal.card_id + index" class="justify-content-center mb-1">
					<b-col cols="5">
						<span>Goals</span>
						<multi-select
							v-if="goal_options"
							:id="`reward-select-input`"
							:options="goal_options"
							:multiple="false"
							v-model="goal.card_id"
						/>
					</b-col>
					<b-col cols="5">
						<span>Quantity</span>
						<b-input v-model="goal.quantity" type="number" />
					</b-col>
					<b-col class="d-flex align-items-center" cols="1">
						<b-button
							variant="danger"
							size="sm"
							@click="deleteGoal(index)"
							>
								<slot>
								<feather-icon icon="Trash2Icon" />
								</slot>
						</b-button>
					</b-col>
				</b-row>
				<div class="text-center">
					<b-button variant="success" @click="addGoal" :disabled="level.goals.length >= 6">Add +</b-button>
				</div> -->
			</b-card>
			<h4>Layer</h4>
			<b-card>
				<h2>Total cards: <span :style="{color: totalCard % 3 !== 0 ? 'red' : '#55DD92'}">{{ totalCard }}</span></h2>
				<b-row class="justify-content-center">
					<b-col v-for="(layer, index) in level.layers" :key="layer._id" cols="5" class="mb-2">
						<layer :layer_map="layer.layer" :layer_index="index" :blocker_options="blocker_options"
						@delete_layer="deleteLayer"
						@update_layer="(layer)=>{level.layers[index].layer = layer}"
						/>
					</b-col>
				</b-row>
				
				<div class="text-center">
					<b-button variant="success" @click="addLayer">Add +</b-button>
				</div>
			</b-card>
		</section>
		<action-buttons update-button back-button delete-button @update="update" :is-updating="isUpdating"
			@delete="deleteItem" :is-deleting="isDeleting"
		/>
	</div>
</template>
<script>
let card_options = [];
let booster_options = [];
let support_item_options = [];
let reward_options = [];
let category_options = [];
const overview_form_schema = [
	{
		cols: 12,
		fields: [
			{
				label: 'Level', field: 'level', input_type: 'select',
				options: [{ text: 'Normal', value: 1 }, { text: 'Hard', value: 2 }, { text: 'Super Hard', value: 3 }],
				validate: { required: true }
			},
			{label: 'Is Published?', field: 'is_active', input_type: 'switch'},
			{ label: 'Display order', field: 'display_order', input_type: 'number', validate: { required: true } },
		]
	}
];
const detail_form_schema = [
	{
		cols: 12,
		fields: [
			{ label: 'Playing time', field: 'playing_time', validate: { required: true } },
			{
				label: 'Card Category', field: 'card_categories', input_type: 'multiselect',
				options: category_options,
				validate: { required: true }
			},
			{label: 'Random Item', field: 'random_item', input_type: 'slot'},
			{
				label: 'Items', field: 'items', input_type: 'multiselect',
				options: card_options,
				validate: { required: true }
			},
			{
				label: 'Booster', field: 'boosters', input_type: 'multiselect-group',
				options: booster_options,
			},
			{
				label: 'Support items', field: 'support_items', input_type: 'multiselect-group',
				options: support_item_options,
			},
		]
	}
]
import service from '../service';
import rewardService from '../../../reward/service';
import supportItemService from '../../../support_item/service';
import cardService from '../../../card/service';
import Layer from './components/Layer.vue';
import blockService from '../../../blocker/service';
import cardCategoryService from '../../../card_category/service';
import {sampleSize} from 'lodash'
export default {
	components: {
		Layer
	},
	data() {
		return {
			overview_form_schema,
			detail_form_schema,
			level: null,
			isUpdating: false,
			isDeleting: false,
			reward_options: [],
			card_options: [],
			blocker_options: [],
			isReady: false,
			random_item_numb: null
		}
	},
	computed: {
		itemId() {
			return this.$route.params.id;
		},
		currentPage(){
			return this.$route.params.currentPage;
		},
		breadcrumbItems() {
			let items = [
				{
					text: 'Level',
					to: { name: 'level-list' },
				},
				{
					text: `${this.level ? this.level.display_order : this.itemId}`, active: true
				},
			]
			return items
		},
		authLanguage() {
			return this.$store.getters["auth/language"];
		},
		goal_options(){
			return (this.level && card_options) ? this.level.items.map((item)=>{
				let card = card_options.find(card => card.value === item)
				return card ? {text: card.text, value: card.value} : {text: null, value: null}
			}) : []
		},
		totalCard(){
			return this.level.layers.reduce((total_card, item) => total_card + item.layer.length, 0);
		}
	},
	watch: {
		'level.card_categories': {
			handler(newVal, oldVal) {
				if (JSON.stringify(newVal) !== JSON.stringify(oldVal) && oldVal){
					this.getCard(this.level?.card_categories)
				}
			},
			deep: true
		}
	},
	async created() {
		await Promise.all([
		this.getBlockers(),
		// this.getBooster(),
		this.getReward(),
		this.getCardCategory(),
		this.getCard(),
		this.getUsableItem(),
		this.getDetail()
		]).then((values)=>{ this.isReady = true })
	},
	methods: {
		async getDetail() {
			this.level = await service.get({ id: this.itemId });
		},
		async update() {
			this.isUpdating = true;
			let data = { ...this.level };
			data.amount_card = this.totalCard;
			await service.update({
				data: JSON.stringify(data),
			});
			this.getDetail();
			this.isUpdating = false;
		},
		async deleteItem() {
			await service.delete({ id: this.itemId });
			this.$router.push({ name: "map-pool" });
		},
		// async getBooster(){
		// 	let data = await boosterService.getAll({category: 'booster'});
		// 	if (data){
		// 		if (booster_options.length){
		// 			booster_options = [];
		// 		}
		// 		let options = [{groupLabel: 'Default', groupValues: []}]
		// 		options[0].groupValues = data.map((item)=> {
		// 			return {
		// 				text: item.title || item.name,
		// 				value: item._id,
		// 			}
		// 		})
		// 		booster_options.push(...options)
		// 	}
		// },
		async getCard(categories = []){
			if (categories.length){
				categories.push(...this.level.card_categories)
				this.level.items = [];
			}
			let data = categories.length ? await cardService.getByCategories({categories: JSON.stringify(categories)}) : await cardService.getAll();
			if (data){
				if (card_options.length){
					card_options = [];
				}
				let options = data.map((item)=> {
					return {
						text: item.title || item.name,
						value: item._id,
					}
				})
				card_options.push(...options);
				if (categories.length){
					detail_form_schema[0].fields[3].options = options
				}
			}
		},
		async getReward(){
			let data = await rewardService.getAll();
			if (data){
				this.reward_options = [];
				this.reward_options = data.map((item)=> {
					return {
						text: item.title || item.name,
						value: item._id,
					}
				})
			}
		},
		async getUsableItem(){
			let data = await supportItemService.getAll();
			if (data){
				if (support_item_options.length){
					support_item_options = [];
				}
				if (booster_options.length){
					booster_options = [];
				}
				let options = [{groupLabel: 'Default', groupValues: []}]
				options[0].groupValues = data.filter(item => item.category  === 'support_item').map((item)=> {
					return {
						text: item.title || item.name,
						value: item._id,
					}
				})
				support_item_options.push(...options)
				options = [{groupLabel: 'Default', groupValues: []}];
				options[0].groupValues = data.filter(item => item.category  === 'booster').map((item)=> {
					return {
						text: item.title || item.name,
						value: item._id,
					}
				})
				booster_options.push(...options)
			}
		},
		async getBlockers(){
			let data = await blockService.getAll();
			if (data){
				this.blocker_options = data.map((item)=>{
					return {
						text: item.name,
						type: item.type,
						color: item.cms_color
					}
				});
			}
		},
		async getCardCategory(){
			let data = await cardCategoryService.getAll();
			if (category_options.length){
				category_options = [];
			}
			let options = data.map((item)=> {return {text: item.name, value: item._id}});
			category_options.push(...options)
		},
		addReward(){
			this.level.rewards.push({reward_id: null, quantity: 0})
		},
		addGoal(){
			this.level.goals.push({card_id: null, quantity: 0})
		},
		addLayer(){
			this.level.layers.push({layer: []})
		},
		deleteLayer(layer_index){
			this.level.layers.splice(layer_index, 1);
		},
		deleteGoal(index){
			this.level.goals.splice(index, 1);
		},
		randomItem(){
			let randomOptions = sampleSize(card_options, this.random_item_numb);
			this.level.items = randomOptions.map(item => item.value);
			this.level.goals = sampleSize(randomOptions, 4).map((item)=>{
				return {
					card_id: item.value,
					quantity: 6
				}
			})
		}
	}
}
</script>