import api from "@/libs/axios";

export default {
  async create(payload) {
    const response = await api.post("/admin/reward", payload);
    return response || null;
  },

  async update(payload) {
    let response = await api.put("/admin/reward", payload);
    return response;
  },

  async delete(payload) {
    const response = await api.delete("/admin/reward", {
      data: payload
    });
    return response;
  },

  async getList(params) {
    let response = await api.get("/admin/reward", {
      params
    });
    return response.data.data;
  },

  async get(params) {
    let response = await api.get("/admin/reward/detail", {
      params
    });
    return response.data.data.document || null;
  },

	async getAll(params) {
    let response = await api.get("/admin/reward/all", {
      params
    });
    return response.data.data.list || null;
  }
};
